import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import {
  ColumnManifestData,
  OptionsClass,
  SectionManifestData
} from "@/domain/entities/MainApp";
import jsPDF from "jspdf";
import autoTable, { ColumnInput } from "jspdf-autotable";
import capitalize from "lodash/capitalize";
import { AccountController } from "../../controllers/AccountController";
import { ellipsisString, formatDate } from "@/app/infrastructures/misc/Utils";

const margin = {
  left: 10,
  right: 10,
  top: 10,
  bottom: 10
};
let doc: any = new jsPDF(),
  roleType = "",
  title = "",
  headerSection: OptionsClass[][] = [],
  columnData: ColumnInput[] = [],
  x = margin.left,
  y = margin.top,
  xx = margin.left,
  currentSection = 1,
  nextSection = 1,
  startY = margin.top + 13,
  startXSignature = 90;

const headerSectionProcess = async (params: SectionManifestData) => {
  // set accound data
  params.headerDetailData.accountData = AccountController.accountData;
  roleType = params.headerDetailData.accountData.account_type_detail?.type;

  // manifest title
  title = `${params.featureName} Manifest`;

  /* HEADER SECTION DATA */
  // array in array for divide header data. ex: [[first-section],[second-section],[third-section],[...-section]]
  const roleName: { [x: string]: string } = {
    "sub-console": "Sub Consolidator"
  };
  const originCity = params.headerDetailData.accountData.account_location;
  const headerSectionsData: OptionsClass[] = [
    new OptionsClass({
      name: `Nama ${roleName[roleType]}`,
      value: capitalize(params.headerDetailData.accountData.name),
      key: "accountTypeName"
    }),
    new OptionsClass({
      name: "Kota Asal",
      value: `${originCity?.city_code} - ${capitalize(
        originCity?.city_name.toLowerCase()
      )}`,
      key: "originCity"
    }),
    new OptionsClass({
      name: `Tanggal ${params.featureName}`,
      value: formatDateWithoutTime(params.headerDetailData.createdAt),
      key: "createdAt"
    }),
    new OptionsClass({
      name: "Total STT",
      value: String(params.data.length) + " STT",
      key: "totalStt"
    }),
    new OptionsClass({
      name: "Total Koli",
      value: formatInputFloat(params.headerDetailData.totalPiece),
      key: "totalPiece"
    }),
    new OptionsClass({
      name: "Total Berat Kotor",
      value: `${formatInputFloat(params.headerDetailData.totalGrossWeight)} Kg`,
      key: "totalGrossWeight"
    }),
    new OptionsClass({
      name: "Total Berat Volume",
      value: `${formatInputFloat(params.headerDetailData.totalVolumeWeight)} Kg`,
      key: "totalVolumeWeight"
    }),
    new OptionsClass({
      name: "Tanggal Update",
      value: formatDate(params.headerDetailData.updatedAt).replace(/,/g, " -"),
      key: "updatedAt"
    }),
    new OptionsClass({
      name: "Diupdate Oleh",
      value: capitalize(ellipsisString(params.headerDetailData.updatedBy, 20)),
      key: "updatedBy"
    })
  ];
  // get & sort list header section data
  const sectionData = [];
  for (const section of params.headerSection) {
    for (const detail of headerSectionsData) {
      if (detail.key === section) {
        sectionData.push(detail);
      }
    }
  }
  // divide header section data to some row
  const row =
    Math.ceil(params.headerSection.length / params.totalColumnHeader) +
    (params.headerSection.length <= params.totalColumnHeader ? 1 : 0);
  let i = 0;

  do {
    headerSection.push(sectionData.splice(0, row));
    i += 1;
  } while (i <= row);

  startY += headerSection[0].length * 5;
  /* END HEADER SECTION DATA */
};

const tableDataProcess = (params: SectionManifestData) => {
  /* TABLE DATA */
  const tableColumnsData = [
    {
      header: "No STT",
      dataKey: "sttNo"
    },
    {
      header: "Total Koli",
      dataKey: "totalPiece"
    },
    {
      header: "Berat Kotor",
      dataKey: "totalGrossWeight"
    }
  ];

  // get & sort list column data
  for (const section of params.columns) {
    for (const detail of tableColumnsData) {
      if (detail.dataKey === section) {
        columnData.push(detail);
      }
    }
  }
  // add propery no each rows
  if (params.columns.length) {
    columnData.unshift({
      header: "No.",
      key: "no"
    });
  }
  //   add column No.
  params.data = params.data.map(
    (item: ColumnManifestData, index: number) =>
      new ColumnManifestData({
        ...item,
        no: index + 1
      })
  );
  /* END TABLE DATA */
};

const signatureProcess = (params: SectionManifestData) => {
  // if there's remaining vertical space in page: start printing next table from the current section
  const remainingVSpace =
    doc.internal.pageSize.height -
    margin.top -
    margin.bottom -
    doc.lastAutoTable.finalY;
  const spaceSignature = 20;

  if (remainingVSpace > 19.5 && currentSection === 1) {
    nextSection = currentSection;
    startY = doc.lastAutoTable.finalY + 10;
  } else {
    startY = margin.top;
    doc.addPage();
  }

  if (params.signature) {
    doc.setFont("Poppins", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    startXSignature += 6;
    doc.text("Station Admin", startXSignature, startY);
    doc.setFont("Poppins", "normal");
    startXSignature += 44;
    doc.text("Kurir", startXSignature, startY);
    doc.setFont("Poppins", "normal");
    startXSignature += 27;
    doc.text("Operation Supervisor", startXSignature, startY);
    startXSignature -= 77;

    startXSignature += 2;
    doc.text(
      "(...........................................)",
      startXSignature,
      startY + spaceSignature
    );
    startXSignature += 38;
    doc.text(
      "(...........................................)",
      startXSignature,
      startY + spaceSignature
    );
    startXSignature += 38;
    doc.text(
      "(...........................................)",
      startXSignature,
      startY + spaceSignature
    );
  }
};

const onResetData = () => {
  roleType = "";
  title = "";
  headerSection = [];
  columnData = [];
  x = margin.left;
  y = margin.top;
  xx = margin.left;
  currentSection = 1;
  nextSection = 1;
  startY = margin.top + 13;
  startXSignature = 90;
};

export const generateManifest2ColumnPotrait = async (
  params: SectionManifestData
): Promise<void> => {
  doc = new jsPDF("p", "mm", "a4", true);

  headerSectionProcess(params);
  tableDataProcess(params);

  // logo
  const importLogo = await import(
    `@/app/ui/assets/images/logo-${roleType}.png`
  );
  const logo = importLogo.default;

  // number of table sections in the page
  const sections = 2;

  // space between each section
  const spacing = 5;

  // calculate each section width
  const widthPaper = doc.internal.pageSize.getWidth();
  const maxRight = widthPaper - margin.right;
  const printWidht = doc.internal.pageSize.width - (margin.left + margin.right);
  const sectionWidth = (printWidht - (sections - 1) * spacing) / sections;

  // add an initial empty page that will be delete later,
  // it is needed for the first setPage(previous_page) call
  doc.addPage();

  autoTable(doc, {
    theme: "plain",
    body: params.data,
    columns: columnData,
    styles: {
      font: "Poppins",
      halign: "left",
      fontSize: 6,
      textColor: "#4D4D4D"
    },
    headStyles: {
      fontStyle: "bold",
      fillColor: "#F5F6F7",
      cellPadding: {
        top: 2,
        right: 1,
        bottom: 2,
        left: 1
      }
    },
    columnStyles: {
      0: { cellWidth: "wrap" }
    },
    bodyStyles: {
      fontStyle: "normal",
      valign: "middle",
      cellPadding: {
        top: 1.2,
        right: 1.3,
        bottom: 1.2,
        left: 1.3
      },
      cellWidth: "auto",
      lineColor: "#4D4D4D"
    },
    tableWidth: sectionWidth,
    margin: {
      left: margin.left + (nextSection - 1) * (sectionWidth + spacing),
      top: startY,
      bottom: margin.bottom
    },
    startY,
    rowPageBreak: "avoid", // avoid breaking rows into multiple sections
    didDrawPage({ table }: any) {
      /* HEADER SECTION */
      if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
        // get logo
        const logoProperties = doc.getImageProperties(logo);
        const logoAspectRatio = logoProperties.height / logoProperties.width;

        const liloLogoWidth = 40;
        doc.addImage(
          logo,
          "PNG",
          maxRight - liloLogoWidth,
          y,
          liloLogoWidth,
          logoAspectRatio * liloLogoWidth,
          `${roleType}-logo`,
          "FAST"
        );

        // title
        y += 6;
        doc.setFont("Poppins", "bold");
        doc.setFontSize(14);
        doc.setTextColor("#4d4d4d");
        doc.text(title, x, y);
        y += 2; // add space after title

        // header detail data
        const valueGap = 35;
        const sectionGap = params.totalColumnHeader === 2 ? 90 : 65;
        for (const data of headerSection) {
          doc.setFontSize(7);
          doc.setTextColor("#4d4d4d");
          doc.setFillColor("#ebecf0");

          for (const [idx, item] of data.entries()) {
            y += 5;
            doc.setFont("Poppins", "semibold");
            doc.text(item.name, x, y);
            xx += valueGap;
            doc.setFont("Poppins", "normal");
            doc.text(": " + item.value, xx, y);

            if (idx === data.length - 1) {
              x += sectionGap;
              xx = x;
              y -= data.length * 5;
            } else {
              xx -= valueGap;
            }
          }
        }
      }

      currentSection = nextSection;
      nextSection = (nextSection % sections) + 1;

      // set left margin which will controll x position of next section
      const shift = (nextSection - 1) * (sectionWidth + spacing);
      table.settings.margin.left = margin.left + shift;

      // if next section is not the fist, move to previous page so when
      // autoTable calls addPage() it will still be the same current page
      if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
        table.settings.margin.top = 15;
      }
      if (nextSection > 1) {
        doc.setPage(doc.internal.getNumberOfPages() - 1);
      }

      /* END HEADER SECTION */
    },
    willDrawCell: (data: any) => {
      // add borders bottom each column
      doc.setDrawColor("#E0E0E0"); // set the border color
      doc.setLineWidth(0.2); // set the border with

      if (data.section === "head") {
        // draw top border each row
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }

      if (data.column.index === 0) {
        // draw left border
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x,
          data.cell.y
        );
      } else if (data.column.index === columnData.length - 1) {
        // draw right border
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }

      // draw bottom border each row
      doc.line(
        data.cell.x,
        data.cell.y + data.cell.height,
        data.cell.x + data.cell.width,
        data.cell.y + data.cell.height
      );
    },
    didParseCell: (data: any) => {
      for (const body of data.table.body) {
        // parse string for weight with Kg
        if (data.column.dataKey === "totalGrossWeight") {
          body.cells["totalGrossWeight"].text = [
            `${formatInputFloat(body.raw["totalGrossWeight"])} Kg`
          ];
        }
      }
    }
  });

  // activate last page for further printing
  doc.setPage(doc.internal.getNumberOfPages());

  signatureProcess(params);

  // delete unused empty page
  doc.deletePage(1);

  doc.setProperties({
    title
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
  onResetData();
};
