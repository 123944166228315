import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { PayloadFinishSessionSTISC, PayloadProcessUpdateSTISC } from "@/domain/entities/StiSc";
import { ChangeStiScRequestInterface } from "../contracts/StiScRequest";

/* eslint-disable @typescript-eslint/camelcase */
export class GenerateApiRequest implements ChangeStiScRequestInterface {
  private sttNumbers: Array<string>;

  constructor(sttNumbers: Array<string>) {
    this.sttNumbers = sttNumbers;
  }
  public toJSON(): string {
    return JSON.stringify({
      stt_no: this.sttNumbers
    });
  }
}

export class UpdateToSTISCRequest implements ChangeStiScRequestInterface {
  payload: PayloadProcessUpdateSTISC = new PayloadProcessUpdateSTISC();
  
  constructor(fields?: Partial<PayloadProcessUpdateSTISC>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}

export class FinishSessionSTISCRequest implements ChangeStiScRequestInterface {
  payload: PayloadFinishSessionSTISC = new PayloadFinishSessionSTISC();

  constructor(fields?: Partial<PayloadFinishSessionSTISC>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}
