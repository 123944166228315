import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { StiSCPresenter } from "@/app/ui/presenters/StiSCPresenter";
import {
  StiSCEntities,
  SttPiecesStiScDetail,
  StiScManifestData,
  RequestListSTISCSession,
  PayloadProcessUpdateSTISC,
  RequestListSTISC,
  PayloadFinishSessionSTISC
} from "@/domain/entities/StiSc";
import { Pagination } from "@/domain/entities/Pagination";
import { FinishSessionSTISCRequest, GenerateApiRequest, UpdateToSTISCRequest } from "@/data/payload/api/StiScApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";

export interface StiSCState {
  isLoading: boolean;
  errorModal: boolean;
  errorCause: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "sti_sc" })
class StiSCStore extends VuexModule implements StiSCState {
  public isLoading = false;
  public periodeStart: any = "";
  public periodeEnd: any = "";
  public stiSCList = new StiSCEntities(new Pagination(0, 0), []);
  public isError = false;
  public errorModal = false;
  public errorCause = "";
  public isOpenSuccess = false;
  public isLoadingPrint = false;
  public isErrorPrint = false;
  public errorPrintCause = "";
  public stiScIdToGeneratePdf = 0;
  public stiSCManifestData = new StiScManifestData(
    0,
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    "",
    []
  );
  public failedStt: Array<any> = [];

  @Action
  public getListStiSC(params: RequestListSTISC) {
    this.setIsLoading(true);
    const presenter = container.resolve(StiSCPresenter);
    return presenter
      .getListStiSC(params)
      .then((res: StiSCEntities) => {
        this.setStiSCList(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setStiSCList(new StiSCEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public fetchStiSCList(params: {isStoSc: boolean}) {
    this.getListStiSC(new RequestListSTISC({
      page: this.stiSCList.pagination.page,
      limit: this.stiSCList.pagination.limit,
      startDate: this.periodeStart ? new Date(this.periodeStart).toLocaleDateString("fr-CA") : "",
      endDate: this.periodeEnd ? new Date(this.periodeEnd).toLocaleDateString("fr-CA") : "",
      isStoScPurposes: params.isStoSc
    }));
  }

  @Action
  public async getDetailSttNumber(params: {
    sttNumber: string;
    callback: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiSCPresenter);
    return await presenter
      .getDetailSttPieces(params.sttNumber)
      .then((res: SttPiecesStiScDetail) => {
        params.callback(res);
        return res;
      })
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
            this.getDetailSttNumber(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public onGenerateStiSc(params: { sttNumber: Array<string> }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiSCPresenter);
    return presenter
      .generateStiSc(new GenerateApiRequest(params.sttNumber))
      .then((res: ResponsePayload) => {
        this.setStiScIdToGeneratePdf(res.stiScId);
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Status Gagal!", () =>
            this.onGenerateStiSc(params)
          )
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async validateSttPiece(sttPiece: string) {
    const presenter = container.resolve(StiSCPresenter);
    return presenter
      .validationScanSTTStiSc(sttPiece)
      .then(res => {
        return {
          isAllowUpdateStatus: res.isAllowUpdateStatus,
          errorMessage: res.errorMessage
        };
      })
      .catch((err: any) => {
        return {
          isAllowUpdateStatus: false,
          errorMessage:
            (err.response && err.response.data.message.id) ||
            "No. STT Pieces tidak ditemukan"
        };
      });
  }

  @Action
  public getStiSCManifestDetail(params: { id: number }) {
    this.setIsLoadingPrint(true);
    const presenter = container.resolve(StiSCPresenter);
    return presenter
      .getStiSCManifestDetail(params.id)
      .then((res: StiScManifestData) => {
        this.setStiSCManifestData(res);
        this.setErrorPrint(false);
        this.setErrorPrintCause("");
        return true;
      })
      .catch((err: any) => {
        this.setErrorPrint(true);
        this.setErrorPrintCause(err.response ? "server" : "internet");
        this.setStiSCManifestData(
          new StiScManifestData(0, "", "", "", 0, 0, 0, 0, "", [])
        );
        return false;
      })
      .finally(() => this.setIsLoadingPrint(false));
  }

  @Action
  public getSTISCSession(param: RequestListSTISCSession) {
    const presenter = container.resolve(StiSCPresenter);
    return presenter.getStiSCSession(param);
  }

  @Action
  public createSTISCSession() {
    const presenter = container.resolve(StiSCPresenter);
    return presenter.createStiSCSession();
  }

  @Action
  public proccessUpdateSTISC(param: PayloadProcessUpdateSTISC) {
    const presenter = container.resolve(StiSCPresenter);
    return presenter.processUpdateSTISC(new UpdateToSTISCRequest({manifestId: param.manifestId, sttNo: param.sttNo}));
  }

  @Action
  public finishSessionSTISC(param: PayloadFinishSessionSTISC) {
    const presenter = container.resolve(StiSCPresenter);
    return presenter.finishStiSCSession(new FinishSessionSTISCRequest({manifestId: param.manifestId}));
  }

  @Mutation
  setIsLoadingPrint(val: boolean) {
    this.isLoadingPrint = val;
  }

  @Mutation
  setStiScIdToGeneratePdf(val: number) {
    this.stiScIdToGeneratePdf = val;
  }

  @Mutation
  setStiSCManifestData(val: StiScManifestData) {
    this.stiSCManifestData = val;
  }

  @Mutation
  setErrorPrint(val: boolean) {
    this.isErrorPrint = val;
  }

  @Mutation
  setErrorPrintCause(val: string) {
    this.errorPrintCause = val;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.isOpenSuccess = value;
  }

  @Mutation
  public setStiSCList(value: StiSCEntities) {
    this.stiSCList = value;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setPeriodeStart(value: Date | null) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: Date | null) {
    this.periodeEnd = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorModal(value: boolean) {
    this.errorModal = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setSttFailed(value: Array<any>) {
    this.failedStt = value;
  }

  @Action
  public setFirstPage() {
    this.stiSCList.pagination.page = 1;
  }
}

export const StiSCController = getModule(StiSCStore);
